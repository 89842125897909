// i18n
import { useTranslation } from 'next-i18next';
// next
import Link from 'next/link';
// mui
import { useMetadata } from '@/hooks/response/useMetadata';
// store
import { useOverallProgress, useQuestionnaire, useSubmitValidation } from '@/store/response';
// types
import { QuestionnaireInstanceStatus } from '@/types/response';
// utils
import { Routes } from '@/utils/urls';
import { ChevronRight } from '@mui/icons-material';
import { Button, Grid, GridProps, Stack } from '@mui/material';

type Props = {
  showStatus: string;
} & GridProps;

export default function PhaseButton({ showStatus, ...rest }: Props) {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.home.buttonLabels' });
  const { answered, reviewed, skipped, started } = useOverallProgress();
  const { questionnaire } = useQuestionnaire();
  const { canSubmit } = useSubmitValidation(questionnaire);
  const { status } = useMetadata();
  const progressed = reviewed + answered + skipped + started;

  return (
    <Grid {...rest}>
      <Stack>
        <Button
          variant="contained"
          LinkComponent={Link}
          href={Routes.Questionnaire.Root}
        >
          {progressed > 0
            ? status === QuestionnaireInstanceStatus.InProgress
              ? t('continueQuestionnaire')
              : status === QuestionnaireInstanceStatus.Submitted
                ? t('viewQuestionnaire')
                : status === QuestionnaireInstanceStatus.Amendments
                  ? t('editQuestionnaire')
                  : t('startQuestionnaire')
            : t('startQuestionnaire')}
          <ChevronRight fontSize="small" />
        </Button>
      </Stack>
    </Grid>
  );
}
